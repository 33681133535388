// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-page-template-js": () => import("./../src/templates/HomePageTemplate.js" /* webpackChunkName: "component---src-templates-home-page-template-js" */),
  "component---src-templates-grid-navigation-template-js": () => import("./../src/templates/GridNavigationTemplate.js" /* webpackChunkName: "component---src-templates-grid-navigation-template-js" */),
  "component---src-templates-course-content-template-js": () => import("./../src/templates/CourseContentTemplate.js" /* webpackChunkName: "component---src-templates-course-content-template-js" */),
  "component---src-templates-course-part-overview-template-js": () => import("./../src/templates/CoursePartOverviewTemplate.js" /* webpackChunkName: "component---src-templates-course-part-overview-template-js" */),
  "component---src-templates-info-page-template-js": () => import("./../src/templates/InfoPageTemplate.js" /* webpackChunkName: "component---src-templates-info-page-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-credits-js": () => import("./../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-edit-page-js": () => import("./../src/pages/edit-page.js" /* webpackChunkName: "component---src-pages-edit-page-js" */),
  "component---src-pages-fi-404-js": () => import("./../src/pages/fi/404.js" /* webpackChunkName: "component---src-pages-fi-404-js" */),
  "component---src-pages-fi-credits-js": () => import("./../src/pages/fi/credits.js" /* webpackChunkName: "component---src-pages-fi-credits-js" */),
  "component---src-pages-fi-edit-page-js": () => import("./../src/pages/fi/edit-page.js" /* webpackChunkName: "component---src-pages-fi-edit-page-js" */),
  "component---src-pages-fi-missing-info-js": () => import("./../src/pages/fi/missing-info.js" /* webpackChunkName: "component---src-pages-fi-missing-info-js" */),
  "component---src-pages-fi-profile-js": () => import("./../src/pages/fi/profile.js" /* webpackChunkName: "component---src-pages-fi-profile-js" */),
  "component---src-pages-fi-report-issue-js": () => import("./../src/pages/fi/report-issue.js" /* webpackChunkName: "component---src-pages-fi-report-issue-js" */),
  "component---src-pages-fi-sign-in-js": () => import("./../src/pages/fi/sign-in.js" /* webpackChunkName: "component---src-pages-fi-sign-in-js" */),
  "component---src-pages-fi-sign-up-js": () => import("./../src/pages/fi/sign-up.js" /* webpackChunkName: "component---src-pages-fi-sign-up-js" */),
  "component---src-pages-missing-info-js": () => import("./../src/pages/missing-info.js" /* webpackChunkName: "component---src-pages-missing-info-js" */),
  "component---src-pages-profile-js": () => import("./../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-report-issue-js": () => import("./../src/pages/report-issue.js" /* webpackChunkName: "component---src-pages-report-issue-js" */),
  "component---src-pages-sign-in-js": () => import("./../src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-up-js": () => import("./../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-sv-404-js": () => import("./../src/pages/sv/404.js" /* webpackChunkName: "component---src-pages-sv-404-js" */),
  "component---src-pages-sv-credits-js": () => import("./../src/pages/sv/credits.js" /* webpackChunkName: "component---src-pages-sv-credits-js" */),
  "component---src-pages-sv-edit-page-js": () => import("./../src/pages/sv/edit-page.js" /* webpackChunkName: "component---src-pages-sv-edit-page-js" */),
  "component---src-pages-sv-missing-info-js": () => import("./../src/pages/sv/missing-info.js" /* webpackChunkName: "component---src-pages-sv-missing-info-js" */),
  "component---src-pages-sv-profile-js": () => import("./../src/pages/sv/profile.js" /* webpackChunkName: "component---src-pages-sv-profile-js" */),
  "component---src-pages-sv-report-issue-js": () => import("./../src/pages/sv/report-issue.js" /* webpackChunkName: "component---src-pages-sv-report-issue-js" */),
  "component---src-pages-sv-sign-in-js": () => import("./../src/pages/sv/sign-in.js" /* webpackChunkName: "component---src-pages-sv-sign-in-js" */),
  "component---src-pages-sv-sign-up-js": () => import("./../src/pages/sv/sign-up.js" /* webpackChunkName: "component---src-pages-sv-sign-up-js" */)
}

